<template>
  <div id="scoreCanvas">
    <!-- <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fff2" color="#fff">
      <van-swipe vertical class="notice-swipe" :autoplay="2000" :show-indicators="false">
        <van-swipe-item>李师傅禁止接单10天李师傅禁止接单10天</van-swipe-item>
        <van-swipe-item>王师傅禁止接单10天李师傅禁止接单10天</van-swipe-item>
        <van-swipe-item>李师傅禁止接单12天李师傅禁止接单10天</van-swipe-item>
      </van-swipe>
    </van-notice-bar> -->
    <div class="scoreNum">
      <ScoreComponent />
      <p class="scoreTitle">您是一个诚信的师傅，继续努力</p>
    </div>
  </div>
</template>
<script>
import ScoreComponent from "@/components/Mine/ScoreComponent.vue";

export default {
  components: {
    ScoreComponent,
  },
  data() {
    return {
      currentRate: 50,
      rate: 100,
    };
  },
};
</script>>
<style lang="less">
#scoreCanvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 30rem;
  background: @themeColor;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  .van-notice-bar {
    position: relative;
    border-radius: 10rem;
    width: 90%;
    margin: 2rem auto;
    color: #fff;
    .textPublic {
      position: absolute;
      top: calc(50% - 1rem);
      left: 1rem;
    }
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
    margin-left: 1rem;
    color: #fff;
    font-size: 1.35rem;
  }
  .scoreNum {
    margin-top: 1rem;
    .scoreTitle {
      font-size: 1.6rem;
      color: #fff;
      text-align: center;
    }
  }
}
</style>